import React from 'react';

import { Slide } from "react-slideshow-image";

export default function ProjektiCard(props) {
    const properties = {
      duration: 2000,
      transitionDuration: 350,
      easing: "ease-in",
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      indicators: false,
      defaultIndex: 2,
      responsive: [

          {
              breakpoint: 200,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  onChange: (previous, next) => {
                },
              }
          }
      ],
    }




  return (

        <div className="flex flex-col lg:flex-row h-min justify-center bg-gray-100 py-6">

            <div className="px-2 order-2 lg:order-1 h-sm w-72 m-auto lg:m-0">
                {console.log(props.slika.length)}
                { Array.isArray(props.slika) ?
                    <Slide {...properties}>
                        {props.slika.map( (element,index) => {
                          return  (
                             <img className="rounded object-center" src={props.slika[index]} alt={props.alt[index]}/>
                          )
                          }) }
                    </Slide>
                    : <img className="rounded object-center" src={props.slika} alt={props.alt}/>
                }


            </div>
            <div className="flex flex-col order-1 lg:order-2">
                <h1 className="text-4xl font-medium px-2 m-auto lg:m-0 max-w-max text-modra">{props.naslov}</h1>
            <p className="text-lg py-6 px-2 max-w-xl text-center lg:text-justify m-auto"> {props.opis} </p>
            </div>
        </div>

)


}
