import React from 'react';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./Components/Pages/Home";
import PodpornikiPage from "./Components/Pages/Podporniki";
import ProjektiPage from "./Components/Pages/Projekti";
import KontaktPage from "./Components/Pages/Kontakt";
import Ideja from "./Components/Pages/Ideja.jsx";
import ErrorPage from "./Components/Pages/ErrorPage";
import KdoSvaPage from "./Components/Pages/kdoSvaPage.jsx";
import Header from "./Components/Header";
import Cookies from "./Components/Cookies";
import Footer from "./Components/Footer";
/* import * as source from "./projektiData.js"; */
import ScrollToTop from "./Components/scrollToTop";

function App() {
  return (
      <Router>
        <ScrollToTop />
        <Header />
        <Cookies />
        <Routes>
            <Route path="/" element={<Home/ >}  />
            <Route path="/podporniki" element={<PodpornikiPage/ >}  />
            <Route path="/naloge" element={<ProjektiPage/ >}  />
            <Route path="/kdo-sva" element={<KdoSvaPage/ >}  />
            <Route path="/kontakt" element={<KontaktPage/ >}  />
            <Route path="/ideja" element={<Ideja/ >}  />
            <Route path="/politika-zasebnosti" element={<KontaktPage/ >}  />
            <Route path="*" element={<ErrorPage/ >}  />
        </Routes>
        <Footer />
      </Router>
      )
}

export default App;