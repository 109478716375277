import React, { useState} from "react";
import { Slide } from "react-slideshow-image";
import PodpornikiCard1 from './PodpornikiCard1';
import PodpornikiCard2 from './PodpornikiCard2';
import * as source from "../projektiData.js";
import 'react-slideshow-image/dist/styles.css'


export default function Podporniki() {

let splosenOpis =`Ker se je Elektronika z robotiko poskusno že izvajala v šolskem letu 2021/22 smo dobili že prve odzive in podpornike projekta.`;


    let kampisem;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    console.log(vw)
    if (vw < 800) {
        kampisem = {
            "1" : "",
            "2" : "",
            "3" : "scaleup",
            "5" : "",
            "6" : "",
            "7" : "",
            "8" : "",
            "9" : "",
    }
} else {
    kampisem = {
        "1" : "",
        "2" : "scaleup",
        "3" : "",
        "5" : "",
        "6" : "",
        "7" : "",
        "8" : "",
        "9" : "",
}
}

  const [scale,setScale] = useState(kampisem);
  const properties = {
    duration: 2500,
    transitionDuration: 350,
    easing: "ease-in",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    indicators: true,
    defaultIndex: 2,
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                onChange: (previous, next) => {
                    let cifra;
                    let prejsnicifra;
                    if( (next+2)>source.slideshowData.length ) {
                        cifra = next + 2 - source.slideshowData.length ;
                        prejsnicifra = prejsnicifra + 2 - source.slideshowData.length;
                    } else {
                        cifra = next + 2;
                        prejsnicifra = prejsnicifra + 2;
                    }
                    // console.log(cifra)
                    kampisem = {
                        "1" : "",
                        "2" : "",
                        "3" : "",
                        "5" : "",
                        "6" : "",
                        "7" : "",
                        "8" : "",
                        "9" : "",
               }
                     kampisem[Object.keys(kampisem)[(cifra-1)]] = "scaleup"
                     // kampisem[Object.keys(kampisem)[(prejsnicifra-1)]] = ""
                    // console.log(kampisem);
                    setScale(kampisem);

              },
            }
        },
        {
            breakpoint: 200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                onChange: (previous, next) => {
                    let cifra;
                    let prejsnicifra;
                    if( (next+1)>source.slideshowData.length ) {
                        cifra = next + 1 - source.slideshowData.length ;
                        prejsnicifra = prejsnicifra + 1 - source.slideshowData.length;
                    } else {
                        cifra = next + 1;
                        prejsnicifra = prejsnicifra + 1;
                    }
                    // console.log(cifra)
                    kampisem = {
                       "1" : "",
                       "2" : "",
                       "3" : "",
                       "5" : "",
                       "6" : "",
                       "7" : "",
                       "8" : "",
                       "9" : "",
               }
                     kampisem[Object.keys(kampisem)[(cifra-1)]] = "scaleup"
                     // kampisem[Object.keys(kampisem)[(prejsnicifra-1)]] = ""
                    // console.log(kampisem);
                    setScale(kampisem);

              },
            }
        }
    ],
  }

  return (
      <>
      <div className="py-14 flex flex-col items-center justify-center bg-white margin_from_menu">
          <h1 className="naslovi  crtaSpodaj">Podporniki</h1>
      <p className="mt-12 text-xl max-w-5xl text-center px-2"> {splosenOpis} </p>
      </div>
      {source.podpornikiData.map( (element,index) => {
        return  (<PodpornikiCard1
                key={index}
                slika={element.slika}
                link = {element.link}
                alt_slika={element.alt_slika}
                logo={element.logo}
                alt_logo={element.alt_slika}
                naslov={element.ime_naziv}
                komentar={element.komentar}
                alineje={element.alineje}
            />)
        }) }
        <div className="h-max items-center py-8 bg-white justify-center ">
            <div>
                <h1 className="flex justify-center lg:text-3xl text-2xl font-medium px-4 py-1 text-center lg:text-left text-modra">Ostali podporniki:</h1>
                
                <Slide {...properties} className="lg:px-48 ">
                    {source.subPodpornikiData.map( (element,index) => {
                      return  (<PodpornikiCard2
                              key={index}
                              logo={element.logo}
                              alt_logo={element.alt_slika}
                              link={element.link}
                              komentar={element.komentar}
                          />)
                      }) }
                </Slide>
            </div>
          </div>
      </>

)


}
