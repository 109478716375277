import React from 'react';
import { Icon } from '@iconify/react';


export default function Slidesnap(props) {




  return (
      <div className={"slides "+props.scale} id={props.id}>
          <div className="">
              {props.ikona}
          </div>
          <div className="ikone-naslov">
             <h2>{props.naslov}</h2>
          </div>
          <div className="ikone-text">
              <p>{props.opis}</p>
          </div>
      </div>  )

}
