import React from 'react';
import ProjektiCard from './projektiCard';

import * as source from "../projektiData.js";

export default function Projekti() {




let splosenOpis =`V sklopu projekta sva do sedaj razvila štiri komplete nalog, vsak od njih zahtevnejši kot prejšnji. Mnogo drugi so še v nastajanju.
Vsi kompleti so vnaprej pripravljeni, da se razdelijo med učence, tako da ni nobene skrbi kje se bo dobilo material za izdelavo. Poleg tega poleg materiala dobite še vse potrebno orodje in navodila in video vsebine z potrebno razlago.
`;


  return (
      <>
      <div className="py-12 flex flex-col items-center justify-center margin_from_menu">
          <h1 className="naslovi  crtaSpodaj">Naloge</h1>
          <p className="textpodnaslovom"> {splosenOpis} </p>
      </div>
      {source.projektiData.map( (element,index) => {
        return  (<ProjektiCard
                key={index}
                slika={element.slika}
                alt={element.alt}
                naslov={element.naslov}
                opis={element.opis}
            />)
        }) }
      </>
)


}
