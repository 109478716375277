import React from 'react';



export default function PodpornikiCard2(props) {




  return (


            <div className="my-2 flex lg:flex-row flex-col items-center align-middle justify-center items-center w-full h-full lg:w-2/3 m-auto py-16">
                <div className="">
                    <a className="m-auto z-20" href={props.link} target="_blank" rel="noreferrer" title="obišči spletno stran">
                    <img className="max-w-md max-h-60 " src={props.logo} alt={props.alt_logo}/>
                    </a>
                </div>
                {/* <p className="text-xl font-bold py-6 px-4 text-gray-600 leading-relaxed"> "{props.komentar}" </p> */}

            </div>

            /* <div className="flex flex-row w-full items-center justify-center px-2">
                <img className="w-56 rounded-3xl" src={props.slika} alt={props.alt_slika}/>

            <div className="flex flex-col order-1 lg:order-2 w-[calc(40%-10%)]">
                    <h1 className="text-3xl font-medium px-4 ">{props.naslov}</h1>
                <p className="text-xl font-bold py-6 px-4"> "{props.komentar}" </p>
                </div>
            </div> */


)


}
