import React , {useState} from 'react';
import emailjs from '@emailjs/browser';
import emailjsdata from './emailjs.js';
import { Icon } from '@iconify/react';
export default function Kontakt() {

    const [name,setName] = useState("")
    const handleName = ({target:{value}}) => setName(value)
    const [email,setEmail] = useState("")
    const handleEmail = ({target:{value}}) => setEmail(value)
    const [message,setMessage] = useState("")
    const handleMessage = ({target:{value}}) => setMessage(value)
    const [os,setOs] = useState("")
    const handleOs = ({target:{value}}) => setOs(value)
    const [poslano,setposlano] = useState("false");

const handleSubmit = (e) => {
    if(poslano === "false") {
    e.preventDefault();
        let podatki = {
            from_name: name,
            osnovnaSola: os,
            reply_to: email,
            message: message,
        }
        emailjs.send('service_tqzqhtm', emailjsdata.TEMPLATE_ID, podatki, emailjsdata.USER_ID)
          .then((result) => {
              // setposlano("true");
          }, (error) => {
              console.log(error.text);
              alert("Joj, zgodila se je napaka =( Golobi, ki so prenašali sporočilo so se ponesrečili");
          });
          // SERVICE OD MITJE
          emailjs.send('service_ppg5p3h', "template_ncvc4v9", podatki, emailjsdata.USER_ID)
            .then((result) => {
                setposlano("true");
            }, (error) => {
                console.log(error.text);
                alert("Joj, zgodila se je napaka =( Golobi, ki so prenašali sporočilo so se ponesrečili");
            });
           setName("");
           setEmail("");
           setMessage("");
           setOs("");
           }


};
function okejfunkcija() {

    setposlano("false");
}
  return (
      <>
      <div className="py-14 flex items-center justify-center margin_from_menu">
          <h1 className="naslovi  crtaSpodaj">Kontakt</h1>
      </div>
      <div  className="py-12 px-2 flex flex-col items-center justify-center bg-gray-200 w-full">
                <p className="text-xl text-gray-700 font-medium py-1 px-2 w-full lg:w-1/2 sm:w-9/12 text-justify">Če se vam zdi ideja o projektu Elektronika z robotiko zanimiva ali imate tudi druga vprašanja, potem ne odlašajte in stopite v stik z nama.</p>
            <p className="justifytest">Dosegljiva sva na telefonsko številko:</p>
        <ul  className="text-xl text-gray-700 font-medium px-2 lg:px-16 w-full lg:w-1/2 sm:w-9/12">
                    <li className="flex flex-row align-middle items-center"><Icon icon="bi:dot" />Vid Čarman : 070 680 067 <a title="Hitri kontakt" href="tel:070-680-067" ><Icon className="mx-4 text-3xl text-modra hover:text-zelena" icon="akar-icons:phone" /></a> </li>
                </ul>
                <p className="justifytest">po elektronski pošti:</p>
            <ul className="text-xl text-gray-700 font-medium px-2 lg:px-16 w-full lg:w-1/2 sm:w-9/12">
                    <li className="flex flex-row align-middle items-center"><Icon icon="bi:dot" /> Vid Čarman - vid@elektronikazrobotiko.si <a title="Hitri kontakt" href="mailto:vid@elektronikazrobotiko.si"><Icon className="mx-2 text-3xl text-modra hover:text-zelena" icon="fontisto:email"   /></a> </li>
                    <li className="flex flex-row align-middle items-center"><Icon icon="bi:dot" /> Mitja Brkopec - mitja@elektronikazrobotiko.si<a title="Hitri kontakt" href="mailto:mitja@elektronikazrobotiko.si"><Icon className="mx-2 text-3xl text-modra hover:text-zelena" icon="fontisto:email" /></a> </li>
                </ul>
                <p className="justifytest">ali preko obrazca spodaj.</p>

            <p className="text-xl text-gray-700 font-medium py-1 px-2  w-full sm:w-9/12 lg:w-1/2 text-justify">Z veseljem vam odgovoriva na vsa vaša vprašanja, odženeva skrbi in pomagava pri enostavni vključitvi programa v osnovne šole. Seveda se je možno dogovoriti za sestanek preko ZOOM-a ali v živo, kjer vam vse pokaževa in podrobneje predstaviva.</p>
  <form className="lg:w-1/2 w-full sm:w-9/12 px-2 lg:px-0" onSubmit={handleSubmit} method="post">

              <div className="flex flex-col ">
                  <label className="inputLabel">Ime in priimek: *</label>
              <input className="input" required type="text" name="" value={name} onChange={handleName} ></input>
              </div>
              <div className="flex flex-col">
                  <label className="inputLabel">Osnovna šola:</label>
                  <input className="input" type="text" name="" value={os} onChange={handleOs} ></input>
              </div>
              <div className="flex flex-col">
                  <label className="inputLabel">Elektronska pošta: *</label>
                  <input className="input" required type="email" name="" value={email} onChange={handleEmail} ></input>
              </div>
              <div className="flex flex-col">
                  <label className="inputLabel">Sporočilo: *</label>
                  <textarea className="input p-2 text-xl"  name="name" rows="6" cols="80" required value={message} onChange={handleMessage}></textarea>
              </div>
              <button type="submit" name="button" className="my-4 h-12 lg:w-1/3 w-2/3 min-w-max max-w-xs bg-zelena text-black rounded font-bold text-lg shadow-xl m-auto items-center btn-fill">
                 Pošlji sporočilo
              </button>
          </form>
      </div>
       {/*Zukaj so podatki */}
      <div className="py-8 flex flex-col items-center justify-center">
          <h1 className="font-medium text-2xl border-black border-b mb-4">Podatki o podjetju</h1>
          <div className="flex flex-col items-center">
              <p>Moč znanja, Mitja Brkopec, s.p.</p>
               <p>Mala Cikava 8A</p>
               <p>8000 Novo Mesto</p>
          </div>
         {/*  <div className="flex flex-col justify-center items-center py-1">
              <p>Matična številka: 8933618000</p>
               <p>Davčna številka: 17557607</p>
          </div> */}
          
      </div>
      <div className={poslano === "false" ? "hidden" : "" +" fixed bottom-1/2 md:bottom-1/2 w-full h-40 min-h-max m-auto bg-transparent z-20"}>
          <div className="flex flex-col justify-center text-xl items-center bg-ozadje w-full md:w-1/2 m-auto shadow-2xl text-zelena">
              <div className="flex flex-row items-center align-middle justify-center item py-2">
                  <Icon className="text-xl align-middle" icon="clarity:success-standard-line" />
              <h3 className="px-2 font-bold">Uspeh !</h3>
              </div>
              <p className="px-2 py-1 text-sm text-center text-white">Vaše sporočilo je bilo uspešno poslano ! <br/> Hvala za zanimanje. <br/> V kratkem lahko pričakujete naš odgovor po e-pošti.</p>
          <button id="btn" class="my-4 h-8 lg:w-1/4 w-2/3 min-w-max max-w-xs bg-zelena text-black rounded font-bold text-lg shadow-xl m-auto items-center btn-fill" onClick={okejfunkcija}>V redu</button>
           </div>
       </div>
      </>
)


}
