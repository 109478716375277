import React from 'react';
import * as source from "../projektiData.js";

export default function Naslovnica() {






  return (
      <>
      <div className="margin_from_menu bg-gray-200 min-h-[calc(content-6rem)] lg:py-16 py-5 w-full h-min md:min-h-[500px] lg:min-h-[500px] md:h-[55%] xl:h-[60%]">
          <div className="pt-6 xl:pt-0 m-auto h-full md:px-36 xl:px-52 max-w-full flex flex-col lg:flex-row lg:gap-28 items-center align-middle">

              <div className="order-2 lg:order-1 lg:px-4 lg:w-full">
                  <h1 className="naslovi crtaSpodaj lg:px-4 lg:text-left text-center font-naslov_evanston"> <span className="text-modra">Elektronika </span> <span className="text-zelena">z robotiko</span></h1>
                  <p className="podstavki leading-relaxed px-4">{source.naslovnicaData.podtekst[0]}</p>
                  <p className="podstavki leading-relaxed px-4">{source.naslovnicaData.podtekst[1]}</p>
              </div>

              <div className="order-1 lg:order-2 lg:px-4">
                  <img className="m-auto lg:w-80 w-48 h-auto" src={source.naslovnicaData.ikona} alt=""/>
                  {/* <div className="text-5xl font-bold flex flex-col items-center font-naslov_evanston">
                      <div className="text-modra">Elektronika </div>
                      <div className="text-zelena">z robotiko</div>
                  </div> */}
              </div>
          </div>
      </div>
      </>
)


}
