import React, { useState, useRef  } from "react";
import { Icon } from '@iconify/react';
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import Slidesnap from './Slidesnap';
import $ from 'jquery';
import * as source from "../projektiData.js";


const ResponsiveExample = () => {

    let kampisem;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    console.log(vw)
    if (vw < 800) {
        kampisem = {
            "1" : "",
            "2" : "",
            "3" : "scaleup",
            "5" : "",
            "6" : "",
            "7" : "",
            "8" : "",
            "9" : "",
    }
} else {
    kampisem = {
        "1" : "",
        "2" : "scaleup",
        "3" : "",
        "5" : "",
        "6" : "",
        "7" : "",
        "8" : "",
        "9" : "",
}
}

    const [scale,setScale] = useState(kampisem);
  const properties = {
    duration: 2500,
    transitionDuration: 350,
    easing: "ease-in",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    indicators: true,
    defaultIndex: 2,
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                onChange: (previous, next) => {
                    let cifra;
                    let prejsnicifra;
                    if( (next+2)>source.slideshowData.length ) {
                        cifra = next + 2 - source.slideshowData.length ;
                        prejsnicifra = prejsnicifra + 2 - source.slideshowData.length;
                    } else {
                        cifra = next + 2;
                        prejsnicifra = prejsnicifra + 2;
                    }
                    // console.log(cifra)
                    kampisem = {
                        "1" : "",
                        "2" : "",
                        "3" : "",
                        "5" : "",
                        "6" : "",
                        "7" : "",
                        "8" : "",
                        "9" : "",
               }
                     kampisem[Object.keys(kampisem)[(cifra-1)]] = "scaleup"
                     // kampisem[Object.keys(kampisem)[(prejsnicifra-1)]] = ""
                    // console.log(kampisem);
                    setScale(kampisem);

              },
            }
        },
        {
            breakpoint: 200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                onChange: (previous, next) => {
                    let cifra;
                    let prejsnicifra;
                    if( (next+1)>source.slideshowData.length ) {
                        cifra = next + 1 - source.slideshowData.length ;
                        prejsnicifra = prejsnicifra + 1 - source.slideshowData.length;
                    } else {
                        cifra = next + 1;
                        prejsnicifra = prejsnicifra + 1;
                    }
                    // console.log(cifra)
                    kampisem = {
                       "1" : "",
                       "2" : "",
                       "3" : "",
                       "5" : "",
                       "6" : "",
                       "7" : "",
                       "8" : "",
                       "9" : "",
               }
                     kampisem[Object.keys(kampisem)[(cifra-1)]] = "scaleup"
                     // kampisem[Object.keys(kampisem)[(prejsnicifra-1)]] = ""
                    // console.log(kampisem);
                    setScale(kampisem);

              },
            }
        }
    ]
  };
  function createCard(slideshowData) {


      console.log(Object.values(scale)[slideshowData.id-1]);

  return (
      <Slidesnap
        scale ={Object.values(scale)[slideshowData.id-1]}
        id={slideshowData.id}
        key={slideshowData.id}
        ikona={slideshowData.ikona}
        naslov={slideshowData.naslov}
        opis={slideshowData.opis}
      />
  );
}

  return (

              <div  className="align-middle bg-white justify-center items-center shadow-2xl py-9 lg:py-2 py-1 md:min-h-[290px] md:h-[40%] relative">
                <Slide {...properties} className="lg:px-48 lg:element1">
                   {source.slideshowData.map(createCard)}
                </Slide>
               </div>

  )
};

export default ResponsiveExample;
