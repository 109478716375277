import { Icon } from '@iconify/react';


 const funFactData = [
     {
             "Date": "1. Januar",
             "Year": "1970",
             "Event": "Ob 00:00:00 ja začetek štetja ure in datuma v večini računalniških sistemov"
         },
         {
             "Date": "4. Januar",
             "Year": "1996",
             "Event": "Je podjetje GM naznanilo prvi serijski električno avtomobil"
         },
         {
             "Date": "12. Januar",
             "Year": "1964",
             "Event": "Se je rodil Jeff Bezos, ustanovitelj podjetja Amazon"
         },
         {
             "Date": "13. Januar",
             "Year": "1867",
             "Event": "So izdelali prvi ročni tiskarski stroj"
         },
         {
             "Date": "18. Januar",
             "Year": "1895",
             "Event": "Je začel svoj program predvajati prvi radio"
         },
         {
             "Date": "22. Januar",
             "Year": "1879",
             "Event": "Je zasvetila prva žarnica"
         },
         {
             "Date": "29. Januar",
             "Year": "1886",
             "Event": "Je Carl Benz naredil prvo cestno vozilo gnano na bencinski pogon"
         },
         {
             "Date": "4. Februar",
             "Year": "2004",
             "Event": "So se prvi uporabniki prijavili na Facebook"
         },
         {
             "Date": "14. Februar",
             "Year": "2003",
             "Event": "Je podjetje Nintendo predstavilo naslednjo generacijo igralnih konzol Game Boy Advance SP"
         },
         {
             "Date": "24. Februar",
             "Year": "1955",
             "Event": "Se je rodil Steve Jobs, ustanovitelj podjetja Apple"
         },
         {
             "Date": "26. Februar",
             "Year": "2011",
             "Event": "Je podjetje Nintendo predstavilo naslednjo generacijo igralnih konzol Nintendo 3DS"
         },
         {
             "Date": "1. Marec",
             "Year": "1998",
             "Event": "Je bil predstavljen prvi prenosni MP3 predvajalnik"
         },
         {
             "Date": "3. Marec",
             "Year": "2017",
             "Event": "Je bila predstavljena \"revolucionarna\" prenosna igralna konzola Nintendo Switch"
         },
         {
             "Date": "4. Marec",
             "Year": "2000",
             "Event": "Je podjetje Sony prestavilo naslednjo generacijo igralnih konzol PlayStation 2"
         },
         {
             "Date": "5. Marec",
             "Year": "1880",
             "Event": "So izdelali prvi slušni aparat"
         },
         {
             "Date": "10. Marec",
             "Year": "1876",
             "Event": "Je zazvonil prvi telefon"
         },
         {
             "Date": "21. Marec",
             "Year": "2001",
             "Event": "Je podjetje Nintendo predstavilo naslednjo generacijo igralnih konzol Game Boy Advance"
         },
         {
             "Date": "24. Marec",
             "Year": "2005",
             "Event": "Je podjetje Sony predstavilo svojo prenosno igralno konzolo PSP"
         },
         {
             "Date": "3. April",
             "Year": "1973",
             "Event": "Je bil izdelan prvi mobilni telefon"
         },
         {
             "Date": "8. April",
             "Year": "1960",
             "Event": "Je bil izumljen internet"
         },
         {
             "Date": "9. April",
             "Year": "1995",
             "Event": "Je bilo ustanovljeno podjetje Amazon"
         },
         {
             "Date": "21. April",
             "Year": "1989",
             "Event": "Je podjetje Nintendo predstavilo prvo prenosno igralno konzolo Game Boy"
         },
         {
             "Date": "3. Maj",
             "Year": "1898",
             "Event": "Se je uspešno potopila prva podmornica"
         },
         {
             "Date": "13. Maj",
             "Year": "2015",
             "Event": "So zagnali platformo Discord"
         },
         {
             "Date": "16. Maj",
             "Year": "1960",
             "Event": "Je bil prestavljen prvi laser"
         },
         {
             "Date": "21. Maj",
             "Year": "1927",
             "Event": "Je prvo letalo uspešno preletelo Atlantski ocean"
         },
         {
             "Date": "24. Maj",
             "Year": "1972",
             "Event": "Je bila izdelana prva igralna konzola"
         },
         {
             "Date": "27. Maj",
             "Year": "2003",
             "Event": "Je bilo narejeno orodje WordPress, ki velja za standard pri izdelavi spletnih strani"
         },
         {
             "Date": "29. Maj",
             "Year": "2013",
             "Event": "Je bilo predstavljeno orodje React v katerem je narejena ta spletna stran"
         },
         {
             "Date": "4. Junij",
             "Year": "2010",
             "Event": "Je podjetje Samsung predstavilo svoj prvi telefon iz serije Galaxy S"
         },
         {
             "Date": "23. Junij",
             "Year": "1996",
             "Event": "Je podjetje Nintendo prestavilo naslednjo generacijo igralnih konzol Nintendo 64"
         },
         {
             "Date": "28. Junij",
             "Year": "1971",
             "Event": "Se je rodil Elon Musk, ustanovitelj podjetij Tesla in SpaceX"
         },
         {
             "Date": "29. Junij",
             "Year": "2007",
             "Event": "Je podjetje Apple predstavilo svoj prvi iPhone"
         },
         {
             "Date": "1. Julij",
             "Year": "1827",
             "Event": "Je fotografijo naredila prva analogna kamera"
         },
         {
             "Date": "2. Julij",
             "Year": "1969",
             "Event": "Je odprava Apollo 11 pristala na luni"
         },
         {
             "Date": "8. Julij",
             "Year": "2011",
             "Event": "Je začel delovati Snapchat"
         },
         {
             "Date": "10. Julij",
             "Year": "1856",
             "Event": "Se je rodil Nikola Tesla"
         },
         {
             "Date": "11. Julij",
             "Year": "1997",
             "Event": "Se je rodil eden od avtorjev izbirnega predmeta Elektronika z robotiko"
         },
         {
             "Date": "15. Julij",
             "Year": "1983",
             "Event": "Je podjetje Nintendo predstavilo svojo prvo igralno konzolo NES"
         },
         {
             "Date": "21. Julij",
             "Year": "1995",
             "Event": "Je podjetje Nintendo predstavilo naslednjo generacijo igralnih konzol Virtual Boy"
         },
         {
             "Date": "1. September",
             "Year": "2016",
             "Event": "So na Kitajskem predstavili TikTok"
         },
         {
             "Date": "4. September",
             "Year": "1998",
             "Event": "Je bilo ustanovljeno podjetje Google"
         },
         {
             "Date": "12. September",
             "Year": "1958",
             "Event": "Je bilo narejeno prvo integrirano vezje"
         },
         {
             "Date": "20. September",
             "Year": "1859",
             "Event": "Je piščanca spekla prva električna pečica"
         },
         {
             "Date": "1. Oktober",
             "Year": "1982",
             "Event": "Se je predvajala glasba iz prvega CD predvajalnika"
         },
         {
             "Date": "6. Oktober",
             "Year": "2010",
             "Event": "Je bila objavljena prva fotografija na Instagram"
         },
         {
             "Date": "21. Oktober",
             "Year": "1998",
             "Event": "Je podjetje Nintendo predstavilo naslednjo generacijo igralnih konzol Game Boy Color"
         },
         {
             "Date": "22. Oktober",
             "Year": "2008",
             "Event": "Je bil predstavljen prvi pametni telefon s sistemom Android - HTC Dream"
         },
         {
             "Date": "29. Oktober",
             "Year": "1969",
             "Event": "Je bilo poslano prvo sporočilo iz računalnika na računalnika"
         },
         {
             "Date": "5. November",
             "Year": "2001",
             "Event": "Je podjetje Nintendo prestavilo naslednjo generacijo igralnih konzol Nintendo GameCube"
         },
         {
             "Date": "11. November",
             "Year": "2006",
             "Event": "Je podjetje Sony prestavilo naslednjo generacijo igralnih konzol PlayStation 3"
         },
         {
             "Date": "12. November",
             "Year": "2020",
             "Event": "Je podjetje Sony predstavilo zadnjo generacijo igralne konzole PlayStation 5"
         },
         {
             "Date": "15. November",
             "Year": "2013",
             "Event": "Je podjetje Sony prestavilo naslednjo generacijo igralnih konzol PlayStation 4"
         },
         {
             "Date": "15. November",
             "Year": "2001",
             "Event": "Je podjetje Microsoft predstavilo svojo prvo igralno konzolo Xbox"
         },
         {
             "Date": "16. November",
             "Year": "1936",
             "Event": "Je bil narejen prvi programljiv računalnik"
         },
         {
             "Date": "18. November",
             "Year": "2012",
             "Event": "Je podjetje Nintendo predstavilo naslednjo generacijo igralnih konzol Nintendo Wii U"
         },
         {
             "Date": "19. November",
             "Year": "2006",
             "Event": "Je podjetje Nintendo predstavilo igralno konzolo Nintendo Wii"
         },
         {
             "Date": "21. November",
             "Year": "1990",
             "Event": "Je podjetje Nintendo predstavilo naslednjo generacijo igralnih konzol Super NES"
         },
         {
             "Date": "21. November",
             "Year": "2004",
             "Event": "Je podjetje Nintendo predstavilo novo revolucionarno konzolo Nintendo DS"
         },
         {
             "Date": "22. November",
             "Year": "2005",
             "Event": "Je podjetje Microsoft predstavilo naslednjo generacijo igralnih konzol Xbox 360"
         },
         {
             "Date": "22. November",
             "Year": "2013",
             "Event": "Je podjetje Microsoft predstavilo naslednjo generacijo igralnih konzol Xbox One"
         },
         {
             "Date": "1. December",
             "Year": "1975",
             "Event": "Je fotografijo naredil prvi digitalni fotoaparat"
         },
         {
             "Date": "3. December",
             "Year": "1994",
             "Event": "Je podjetje Sony predstavilo svojo prvo igralno konzolo PlayStation"
         },
         {
             "Date": "8. December",
             "Year": "1997",
             "Event": "Se je rodil eden od avtorjev izbirnega predmeta Elektronika z robotiko"
         },
         {
             "Date": "12. December",
             "Year": "2019",
             "Event": "Je podjetje Microsoft predstavilo zadnjo generacijo igralne konzole Xbox Series X in Series S"
         },
         {
             "Date": "17. December",
             "Year": "1903",
             "Event": "Je poletelo prvo letalo katerega polet je trajal 12 sekund"
         },
         {
             "Date": "17. December",
             "Year": "2011",
             "Event": "Je podjetje Sony predstavilo svojo prenosno igralno konzolo PS Vita"
         },
         {
             "Date": "23. December",
             "Year": "1947",
             "Event": "So v Bellovih laboratorijih naredili prvi tranzistor"
         },
         {
             "Date": "14. Maj",
             "Year": "1984",
             "Event": "Se je rodil Mark Zuckerberg, ustanovitelj podjetja Facebook"
         },
         {
             "Date": "1. December",
             "Year": "2021",
             "Event": "So Facebook, Instagram in WhatsApp začela spadati pod podjetje Meta"
         },
         {
             "Date": "20. November",
             "Year": "1985",
             "Event": "Je Microsoft predstavil prvo različico operacijskega sistema Windows"
         }
    ]
export default funFactData;
