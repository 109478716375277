import React from 'react';
import { Icon } from '@iconify/react';


export default function Nagovor() {

let ikona = "/images/logo.png";
let podtest1 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."



  return (
      <>
        <div className="min-h-max md:pt-24 lg:pt-8 2xl:pt-6 bg-gray-200">
            <div className="flex justify-center items-center align-middle py-12">
                <h1 className="naslovi crtaSpodaj">Nagovor</h1>
            </div>
            <div className="flex justify-center items-center align-middle py-6">
                <iframe className="w-11/12 md:w-2/3 lg:w-3/5 h-64 md:h-[calc(100vh-15rem)]" src="https://www.youtube.com/embed/qlhxa94GOaA?" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

            </div>

        </div>
      </>
)


}
