import React from 'react';



export default function ErrorPage() {
    return (
        <div className="w-screen lg:h-[calc(100vh-17rem)] h-[calc(100vh-14rem)] flex flex-col items-center justify-center">
            <h1 className="naslovi">Ta stran žal ne obstaja </h1>
        <p className="text-3xl">😢</p>
        </div>

    )
}
