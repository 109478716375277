import React from 'react';
import { Icon } from '@iconify/react';
//import ReactDOM from 'react-dom';

function Cookies() {
    const saved = localStorage.getItem("cookie-status");
    const [cookie, setCookie] = React.useState(saved);

    function piskotek() {
        setCookie("true");
        localStorage.setItem("cookie-status", "true")
    }

    return (
        <>
            <div className={cookie == "true" ? "hidden" : "" +"fixed bottom-0 md:bottom-10 w-full h-40 min-h-max m-auto bg-transparent z-20"}>
                <div className="flex flex-col justify-center items-center bg-ozadje w-full md:w-1/2 m-auto shadow-2xl text-zelena">
                    <div className="flex flex-row items-center align-middle justify-center item py-2">
                        <Icon className="text-xl align-middle" icon="lucide:cookie" />
                        <h3 className="px-2 font-bold">Piškotki</h3>
                    </div>
                    <p className="px-2 py-1 text-sm text-center text-white">To spletno mesto uporablja piškotke za boljšo uporabniško izkušnjo. Če boste spletno stran uporabljali še naprej, s tem privolite v njihovo nadaljnjo uporabo.</p>
                     <button id="btn" class="my-4 h-12 lg:w-1/3 w-2/3 min-w-max max-w-xs bg-zelena text-black rounded font-bold text-lg shadow-xl m-auto items-center btn-fill" onClick={piskotek}>V redu</button>
                 </div>
             </div>
        </>
);
}

export default Cookies;
