import React from 'react';
import * as source from "../../projektiData.js";

export default function Ideja() {
    return (
        <div className="py-16 flex flex-col items-center justify-center mt-24">
            <h1 className="naslovi  crtaSpodaj">Vse se je začelo na fakulteti:</h1>
        {source.nasaZgodbaData.text.map( (element,index) => {
              return  (

                  <p className="mt-12 text-2xl text-gray-600 w-full lg:w-2/3 text-justify px-3 leading-relaxed ">{element}</p>

      )
              }) }
            <img className="lg:w-2/3 py-9 px-2" src={source.slikakdoSva} alt="Vid Čarman in Mitja Brkopec"/>
        </div>
    )
}
