import React from 'react';
import * as source from "../../projektiData.js";


export default function KdoSvaPage() {
    return (
        <>
        <div className="py-12 flex flex-col items-center justify-center margin_from_menu">
            <h1 className="naslovi  crtaSpodaj">Kdo sva? </h1>
        </div>

                <div className="flex lg:flex-row flex-col lg:w-full w-full h-full justify-center items-center px-1 mt-2">

                    <img className="order-1 lg:order-2 lg:w-64 w-2/3 rounded-3xl self-start m-auto lg:m-0 sm:w-1/3" src={source.kdoSmoData[0].slika} alt=""/>

                    <div className="flex flex-col order-1 lg:order-1 w-1/2 lg:w-[calc(50%-10%)] w-screen items-start self-start py-4 lg:py-0">
                            <h1 className="lg:text-3xl text-2xl font-medium px-3 py-1"><span className="text-zelena"> Vid </span><span className="text-modra"> Čarman </span></h1>
                        {/* <p className="text-lg px-3 text-gray-600">"{source.kdoSmoData[0].title}"</p> */}
                    <p className="text-xl text-gray-600 font-bold py-3 px-3 leading-relaxed text-justify">Živjo! Sem Vid in prihajam iz okolice Medvod. Po pridobljeni diplomi iz elektrotehnike, sem se odločil, da svoje šolanje še naprej nadaljujem na magistrskem študiju na Fakulteti za elektrotehniko, kjer sem z letošnjim letom zaključil s predavanji.</p>
                    <p className="text-xl text-gray-600 font-bold py-3 px-3 leading-relaxed text-justify">V času študija sem opravljal različna študentska dela, nekatera priložnostna in druga dlje trajajoča. Največ pa sem odnesel v podjetju Omega Air d.o.o., kjer sem imel priložnost spoznati tako delo v proizvodnji kot delo v razvojnem oddelku, zato lahko rečem, da sem videl kakšen je celoten proces izdelave produkta preden ga lahko predstavijo tržišču. Drugo podjetje pa je bilo EMB Bizant d.o.o. (sedaj del podjetja Kolektor Group d.o.o.), kjer sem povezoval elektro omare v katerih sem se srečal z visokonapetostnimi vezji in avtomatiko za upravljanje.</p>
                    <p className="text-xl text-gray-600 font-bold py-3 px-3 leading-relaxed text-justify">Že v mladih letih sem se navduševal nad elektrotehniko, zahvala zato gre mojemu očetu, ki mi je pomagal izdelati moje prvo tiskano vezje in me še danes veseli, da v svojem prostem času uporabim vse pridobljeno znanje in izdelam kakšen projekt.</p>



                </div>
                </div>

                <div className="flex lg:flex-row flex-col lg:w-full w-full h-full justify-center items-center px-1 lg:py-12 mt-2">

                        <img className="order-1 lg:order-2 lg:w-64 w-2/3 rounded-3xl self-start m-auto lg:m-0 sm:w-1/3" src={source.kdoSmoData[1].slika} alt=""/>

                    <div className="flex flex-col order-1 lg:order-1 w-1/2 lg:w-[calc(50%-10%)] w-screen items-start self-start py-4 lg:py-0">
                            <h1 className="lg:text-3xl text-2xl font-medium px-3 py-1"> <span className="text-zelena"> Mitja </span><span className="text-modra"> Brkopec </span> </h1>
                        {/* <p className="text-lg px-3 text-gray-600">"{source.kdoSmoData[1].title}"</p> */}
                    <p className="text-xl text-gray-600 font-bold py-3 px-3 leading-relaxed text-justify">Živjo! Sem Mitja in prihajam iz okolice Novega Mesta. Po izobrazbi sem diplomirani inženir elektrotehnike in trenutno tudi novi učitelj elektrotehnike na Srednji elektro šoli in tehniški gimnaziji Novo mesto. </p>
                    <p className="text-xl text-gray-600 font-bold py-3 px-3 leading-relaxed text-justify">Svoje izkušnje sem v času študija tako pridobival in kasneje izpolnjeval v avtomobilski industriji - v podjetju za proizvodnjo in komercializacijo avtomobilov Revoz ter Revoz-Trigo in s praktičnim usposabljanjem na področju aplikativne elektrotehnike v razvojnem oddelku Hella Saturnus, kjer sem sodeloval pri razvoju in proizvodnji svetlobne opreme za avtomobile. Kasneje sem svoje znanje nadgrajeval tudi v podjetju Renault-Tech in v proizvodnji hibridnih vezij HYB d.o.o.  </p>
                </div>

                </div>
                <div className="flex lg:flex-row flex-col lg:w-full w-full h-full justify-center items-center px-1 lg:py-12 mt-2">

                        <img className="order-1 lg:order-2 lg:w-64 w-2/3 rounded-3xl self-start m-auto lg:m-0 sm:w-1/3" src={source.slikakdoSva} alt=""/>

                    <div className="flex flex-col order-1 lg:order-1 w-1/2 lg:w-[calc(50%-10%)] w-screen items-start self-start py-4 lg:py-0">

                    <p className="text-xl text-gray-600 font-bold py-3 px-3 leading-relaxed text-justify">Vedno sva rada pomagala mladim, zaradi česar naju je marsikdo vprašal zakaj sva se vpisala na tehniško šolo namesto pedagoške. Ampak ker se zavedava kako težko jim mora biti izbrati pravo smer izobraževanje v tej poplavi informacij, ko lahko na internetu res najdeš vse. Ta odločitev je še ne tako dolgo nazaj vplivala tudi na naju in po spletu okoliščin sva se spoznala in sedaj delava to, kar ravnokar berete.</p>
                    <p className="text-xl text-gray-600 font-bold py-3 px-3 leading-relaxed text-justify">Najina želja je, da bi se mladi našli v svoji stroki, kot sva se v elektrotehniki znašla midva in tako pridobili več od svoje izobrazbe že v zgodnjih letih – več znanja, več izkušenj, predvsem pa več volje do dela in raziskovanja.</p>

                    <p className="text-xl text-gray-600 font-bold py-3 px-3 leading-relaxed text-justify">Tako se je pravzaprav porodila ideja o projektu, želela sva uporabiti dosedanje izkušnje in jih predstaviti mladim. Ker so vse informacije pridobljene pri pouku le orisale dogajanje v realnem, praktičnem svetu, želiva ta svet približati mladim. Pripravila sva že štiri komplete nalog, mnogo jih je še v nastajanju, ki jih želiva pokazati učencem v osnovnih šolah. Vsi kompleti so novi, zanimivi, inovativni, predvsem pa drugačni kot to, česar so otroci sedaj vajeni v šolskih klopeh.</p>

        </div>

                </div>

        </>
         )
}
