import React from 'react';
import Naslovnica from '../Naslovnica';
import ResponsiveExample from '../Slideshow';
import Nagovor from '../Nagovor';


export default function Home() {
    return (
        <>
        <div className="lg:h-[calc(100vh-5rem)] h-max">
            <Naslovnica />
            <ResponsiveExample />
        </div>

        <Nagovor />
        </>

    )
}
