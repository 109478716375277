import React from 'react';
import Projekti from '../Projekti';



export default function ProjektiPage() {
    return (
            <Projekti />
    )
}
