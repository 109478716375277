import React from 'react';
import { Icon } from '@iconify/react';
import {BrowserRouter as Router, Link  } from "react-router-dom";
import * as source from "../projektiData.js";
export default function Header() {

let ikona = "/images/logo.png";

const [odprtostMenija,setOdprtostMenija] = React.useState({
    "open" : "-right-full",
    "barva" : "text-modra"
});

let leto = new Date().getFullYear();

    function odpriMeni() {
        odprtostMenija.open === "right-0" ?
         setOdprtostMenija({
            "open" : "-right-full",
            "barva" : "text-modra"
        })
         : setOdprtostMenija({
            "open" : "right-0",
            "barva" : "text-zelena"
        })

    }

  return (
      <>
              <header className="fixed top-0 w-full h-20 bg-ozadje shadow-2xl flex flex-row justify-between border-b-3 border-zelena z-20">
                        <Link className="flex flex-row gap-2 items-center p-1 transition-all text-2xl duration-500 hover:text-3xl"  to="/">
                            <img className="mx-3 max-h-16" src={ikona} alt="Elektronika z robotiko logotip"/>
                            <h1 className="tracking-wide text-white font-medium font-naslov_evanston"><span className="text-modra">Elektronika </span><div className="text-zelena">z robotiko</div></h1>
                        </Link>

                     {/* IKONA za telefone */}
                     <div className="flex items-center lg:hidden">
                         <Icon className={"text-6xl cursor-pointer "+odprtostMenija.barva} icon="ci:menu-alt-02"  onClick={odpriMeni} />
                     </div>
                     {/* MENI ZA VELIKE EKRANE lg+ */}
                        <div className="hidden lg:flex mx-20 flex-row gap-10 items-center">

                            {source.menuLines.map( (element,index) => {
                              return  (<Link
                                          className="menuButton" 
                                          key = {index}
                                          to={element.link}>
                                          {element.text}

                                    </Link>)
                              }) }

                         </div>


            </header>
          {/* Meni ampak za telefon */}
          <div className={"w-full fixed top-0 z-20 margin_from_menu h-[calc(100vh_-_7rem)] bg-gray-700 uppercase transition-all duration-300 " + odprtostMenija.open }>
              <div className="overflow-y-auto h-[calc(100%_-_3rem)] flex flex-col items-center mt-4">
                      {source.menuLines.map( (element,index) => {
                        return  (<Link
                                    className="menuButton"
                                    key = {index}
                                    to={element.link}
                                    onClick={(e) => {
                                        setOdprtostMenija({
                                           "open" : "-right-full",
                                           "barva" : "text-modra"
                                        })
                                    }}> {element.text}
                                </Link>)
                        }) }

              </div>
                      <footer className="p-2 fixed bottom-0 w-full h-12 bg-gray-600 flex flex-col content-center items-center text-xs text-white normal-case">
                          <p> Elektronika z robotiko | Vse pravice pridržane © {leto}</p>
                          <a className="p-1 underline text-blue-600 hover:text-blue-800" href="/politikaZasebnosti" >Politika zasebnosti</a>



                      </footer>

          </div>
      </>
)

}
