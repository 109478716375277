import { Icon } from '@iconify/react';
// Meni {
// text : je kar se prikaže v gumbu odprtostMenija
// link : je povezava do strani oziroma url primer-> naslov.com/projekti
//
//}
//   landing: () => (<Home />),
export const slikakdoSva = "/images/kdosva.jpg";
export const menuLines = [
    {
        text : "Domov",
        link : "/",
    },
    {
        text : "Ideja",
        link : "/ideja"
    },
    {
        text : "Kdo sva?",
        link : "/kdo-sva"
    },
    {
        text : "Naloge",
        link : "/naloge"
    },
    {
        text : "Podporniki",
        link : "/podporniki"
    },
    {
      text : "Naroči",
      link : "/naroci"
  },
    {
        text : "Kontakt",
        link : "/kontakt"
    },
];

export const naslovnicaData =
    {
        naslov: "Projekt pa pou",
        podtekst:  [
            //odstavek 1 če hočeš več odstavkov jih razčleniš po spodnjem vzorcu
            `Elektronika z robotiko je projekt, ki je nastal izpod rok dveh takrat še študentov elektrotehnike na Fakulteti za elektrotehniko, univerze v Ljubljani. Najina želja je bila otrokom prikazati, da je elektrotehnika zabavna in zanimiva, in ni le tista gospa, ki se ne pusti »šlatat«.`,
            //odstavek 2
            `Sedaj želiva projekt predstaviti tudi širši javnosti, predvsem osnovnim šolam, ki bi lahko s pomočjo pripravljenih navodil in video vsebin učencem v sklopu izbirnega predmeta, krožka ali kot del katerega drugega učnega načrta učencem predstavili zakaj je elektrotehnika tako uporabna.`,
    ],
        ikona: "/images/logo.png"
    };

export const slideshowData = [
  {
    id: 1,
    ikona: <Icon className="ikone-ikona" icon="fluent:brain-circuit-20-regular" width="110" height="110" />,
    naslov: "POUČNO",
    opis: "Skozi predmet boste spoznali, da elektronika ni namenjena samo za polnjenje vaših mobilnih naprav in uporabo mikrovalne pečice, ampak je mnogo več."
  },
  {
    id: 2,
    ikona: <Icon className="ikone-ikona" icon="fluent:arrow-trending-20-filled" width="110" height="110" />,
    naslov: "AMBICIOZNO",
    opis: "Vsak od projektov predstavlja nov izziv, ki je drugačen od prejšnjega."
  },
  {
    id: 3,
    ikona: <Icon className="ikone-ikona" icon="ph:fingerprint-simple-duotone" width="110" height="110" />,
    naslov: "STROKOVNO",
    opis: "Predmet je bil razvit s strani študentov elektrotehnike pod budnim očesom mentorjev."
  },
  {
    id: 4,
    ikona: <Icon className="ikone-ikona" icon="ic:outline-verified" width="110" height="110" />,
    naslov: "ENOSTAVNO",
    opis: "Vse orodje in material za projekte dobite že pripravljen, zato je lahko vaš fokus povsem na delu."
  },
  {
    id: 5,
    ikona: <Icon className="ikone-ikona" icon="bx:party" width="110" height="110" />,
    naslov: "ZABAVNO",
    opis: "Zabava od katere boste odnesli uporabne izdelke in znanje."
  },
  {
    id: 6,
    ikona: <Icon className="ikone-ikona" icon="ps:bank-safe" width="110" height="110" />,
    naslov: "VARNO",
    opis: "Razen visokim temperaturam spajkalnika ne boste izpostavljeni nobenim nevarnim snovem."
  },
  // {
  //   id: 7,
  //   ikona: <Icon className="ikone-ikona" icon="akar-icons:react-fill" width="110" height="110" />,
  //   naslov: "KREATIVNO",
  //   opis: "Projekti spodbujajo kreativno razmišljanje."
  // },
  {
    id: 7,
    ikona: <Icon className="ikone-ikona" icon="fluent:thinking-20-regular" width="110" height="110" />,
    naslov: "PREMIŠLJENO",
    opis: "Vsaj od projektov je skrbno izbran in prilagojen začetnikom"
  },

];

export const projektiData = [
  {
    slika: ["/images/Compressed/KIT_kompleti_1.png","/images/KIT_kompleti_2.png"],
    alt: ["Primer ploščic v KIT kompletu","Primer ploščic v KIT kompletu"],
    naslov: "Učno razvojne ploščice",
    opis: "Prva naloga obsega spajkanje in povezovanje enostavnih ploščic na katerih so narisane osnovne elektrotehniške komponente. Učenci si ploščice sami zaspajkajo, se jih naučijo povezati in tako s pomočjo navodil sestavijo najrazličnejše električne tokokroge."
  },
  {
    slika: "/images/Compressed/Novoletna_smrečica.gif",
    alt: "Novoletna smrečica",
    naslov: "Novoletna smrečica",
    opis: "Pri drugi nalogi se učenci spoznajo z naprednejšimi tehnikami spajkanja in prvim procesorjem."
  },
  {
    slika: "/images/Compressed/Tri_v_vrsto.jpg",
    alt: "Tri v vrsto",
    naslov: "Tri v vrsto",
    opis: "Naslednja v sklopu nalog je igra tri v vrsto, le da namesto križcev in krožcev na tiskanini LED diodo obarvamo rdeče ali zeleno. Učenci se spoznajo z enostavnim mikroprocesorjem in vidijo kaj vse je potrebno za njegovo delovanje."
  },
  {
    slika: "/images/Compressed/Avtomobilček.jpg",
    alt: "Robotski avtomobilček",
    naslov: "Robotski avtomobilček",
    opis: "Zadnja od nalog je programljiv avtomobilček. Učenci si lahko oddahnejo saj je projekt spajkalno manj zahteven kot prejšnje dva, morajo pa sami sestaviti celotno konstrukcijo. Predmet zaključimo s predstavitvijo grafičnega programiranja, kjer prikažemo česa vse je avtomobilček zmožen."
  },


];

 export const podpornikiData = [
  {
    logo: "/images/FElogo.png",
    alt_logo: "OŠ Polhov Gradec",
    link: "https://fe.uni-lj.si/sl/",
    slika: "/images/Marko_Jankovec.jpg",
    alt_slika: "Marko Jankovec",
    ime_naziv: "izr. prof. dr. Marko Jankovec, univ. dipl. inženir elektrotehnike",
    komentar: `Vid in Mitja sta prišla do mene z idejo, da bi želela izkušnje, pridobljene med študijem na Fakulteti za elektrotehniko, deliti za mladimi v obliki priprave raznih kompletov s področja elektronike in robotike. Idejo sem z navdušenjem podprl s svetovanjem in materialom. Projekt smo ob podpori podjetja Metrel d.d. lani jeseni pilotno zastavili na OŠ Polhov Gradec. Učenci so skozi igro, delo in učenje uspeli navdušiti za tehniko, elektroniko in programiranje. Na koncu smo bil vsi zadovoljni:`,
    alineje: (<ul className="text-xl text-gray-600 font-bold text-justify md:text-justify list-disc px-8 ml-4">
        <li>Mi, ki smo dosegli začrtane cilje,</li>
        <li>Učenci, ki so odnesli domov lastno izdelane elektronske igrače in zato dobili tudi lepe ocene,</li>
        <li>Učiteljica, ki se je ravno tako veliko novega naučila in speljala predmet ob naši podpori.</li>
    </ul>),
  },
  {
    logo: "/images/ospolhovGradec.png",
    alt_logo: "Fakulteta za elektrotehniko",
    link: "http://www.ospg.si/",
    slika: "/images/Nastja_Mihovec.jpg",
    alt_slika: "Nastja Mihovec",
    ime_naziv: "Nastja Mihovec, učiteljica TIT, OIP in MAT",
    komentar: `Šolsko leto 2021/2022 se je na OŠ Polhov Gradec prvič izvajal predmet Robotika v tehniki, kjer so učenci lahko spoznali osnovne elektronske elemente, kako delujejo, jih spajkali, vezali številna vezja, spoznavali kako kaj deluje in kje to lahko uporabijo v realnem življenju ter izdelali nekaj kompletov (novoletna smrečica, tri v vrsto, robotek - avtomobilček, spajkanje kocke iz uporov, risanje vezij...).
Učenci so se naučili veliko novega, izurili veščine spajkanja, miselne sposobnosti so dobro razvijali in predvsem so jih  zanimale novitete na trgu ter bili so pripravljeni vedno poskusiti kaj novega.
Kot učiteljica vidim, da imajo učenci radi izzive, želijo si spoznavati novitete in predvsem lastnoročno kaj ustvariti. Izbirni predmet je potekal zelo sproščeno, z veliko razmišljanja in veliko novega znanja, kot za učence in učiteljico. Projekti so zasnovani glede na starost otrok in aktualnosti.
Za izpeljavo tega predmeta pa se je potrebno zahvaliti predvsem Fakulteti za elektrotehniko (g. Marku Jankovcu), podjetju Metrel d.d. in fantoma za pomoč pri izvajanju (Vidu Čarmanu in Mitji Brkopcu)
`
  },
];

export const subPodpornikiData = [
 {
   logo: "/images/Metrel_logo.png",
   alt_logo: "Metrel",
   link: "https://www.metrel.si",
   komentar: ""
 },
 {
   logo: "/images/iSystem_Logo.png",
   alt_logo: "iSystem",
   link: "https://www.isystemlabs.si/",
   komentar: ""
 },
 {
   logo: "/images/rra lur_slika.jpg",
   alt_logo: "rra lur_slika",
   link: "https://rralur.si/",
   komentar: ""
 },
 {
   logo: "/images/poni_barvna_OS.png",
   alt_logo: "PONI",
   link: "https://rralur.si/projekti/poni-lur/",
   komentar: ""
 },
 {
  logo: "/images/eti.jpg",
  alt_logo: "Eti inženirske storitve",
  link: "https://www.eti.si/",
  komentar: ""
},
{
  logo: "/images/janez_logo.png",
  alt_logo: "JANEZ alarmni sistemi",
  link: "https://www.janez.si/",
  komentar: ""
},
 {
   logo: "/images/Würth_Elektronik_Logo.png",
   alt_logo: "Würth Elektronik",
   link: "https://www.we-online.com/web/en/wuerth_elektronik/start.php",
   komentar: ""
  },
];


// Text na strani IDEJA oziroma Naša zgodba
export const nasaZgodbaData = {
    naslov: "Najina zgodba:",
    text: [
        //odstavek 1 če hočeš več odstavkov jih razčleniš po spodnjem vzorcu
        `Ko sva se spoznala in ugotovila, da ima kar nekaj študentov po končanem izobraževanju pomanjkljivo praktično znanje s področja elektrotehnike. Po krajši raziskavi sva odkrila, da enako velja tudi za dijake, ki so obiskovali elektrotehniško srednjo šolo. `,
        //odstavek 2
        `Vendar problem ne leži v pomanjkljivem poučevanju učiteljev in profesorjev ali nekompetentnosti mladostnikov, temveč v njihovem pomanjkanju zanimanja in motivacije za odkrivanje novih znanj in spretnosti. `,

        `Zavedava se, da se delovne navade začnejo graditi že v mladih letih in ker otroke veliko bolj pritegnejo naloge, kjer lahko sami nekaj ustvarijo in imajo za končni rezultat fizični izdelek, sva se odločila, da bodo najine naloge zasnovane na praktičnem delu`,

        `Porodila se je ideja, da bi zasnovala program, s katerim bi pomagala pri izvajanju praktičnega poučevanja elektrotehnike na zanimiv in inovativen način za vse znanja željne posameznike.`,

        `Celoten program sva poimenovala Elektronika z robotiko, ki sva ga v šolskem letu 2021/22 tudi že poskusno začela izvajati. Najina vizija je širiti znanje in zanimanje za elektrotehniko ter motivirati učence za morebitno nadaljnje izobraževanje v tej smeri.`,

],
}

export const kdoSmoData = [
        {
        slika: "/images/vidcarman.jpg",
        ime: "Vid Čarman",
        title : "Šampijon elektronike z robotiko",
        opis: "Čarli je svojo štromarsko pot začel na vegovi, kjer ga je profesorica Lasbaher navdušila za elektroniko.",
    },
    {
    slika: "/images/Mitja_Brkopec.jpg",
    ime: "Mitja Brkopec",
    title : "Asistent elektronike z robotiko",
    opis: `Živjo! Sem Mitja in prihajam iz okolice Novega Mesta. Po izobrazbi sem diplomirani inženir elektrotehnike in trenutno tudi novi učitelj elektrotehnike na Srednji elektro šoli in tehniški gimnaziji Novo mesto.
Svoje izkušnje sem v času študija tako pridobival in kasneje izpolnjeval v avtomobilski industriji - v podjetju za proizvodnjo in komercializacijo avtomobilov Revoz ter Revoz-Trigo in s praktičnim usposabljanjem na področju aplikativne elektrotehnike v razvojnem oddelku Hella Saturnus, kjer sem sodeloval pri razvoju in proizvodnji svetlobne opreme za avtomobile. Kasneje sem svoje znanje nadgrajeval tudi v podjetju Renault-Tech in v proizvodnji hibridnih vezij HYB d.o.o.
`,
    }
];
