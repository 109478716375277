import React from 'react';



export default function PodpornikiCard1(props) {




  return (

        <div className="flex flex-col h-min justify-center items-center py-6 bg-white">
            <div className="my-4">
                <a href={props.link} target="_blank" rel="noreferrer" title="obišči spletno stran">
                <img className=" lg:h-32 px-2" src={props.logo} alt={props.alt_logo}/>
                </a>
            </div>
            <div className="flex lg:flex-row flex-col lg:w-full w-full h-full justify-center items-center px-1 mt-2">

                    <img className="lg:w-64 w-2/3 rounded-3xl self-start m-auto lg:m-0 sm:w-1/3" src={props.slika} alt={props.alt_slika}/>

                    <div className="flex flex-col order-1 lg:order-2 w-1/2 lg:w-[calc(50%-10%)] w-screen">
                        <h1 className="lg:text-3xl text-2xl font-medium px-4 py-1 text-center lg:text-left text-modra"> {props.naslov} </h1>

                    <p className="text-xl text-gray-600 font-bold py-6 px-4 leading-relaxed lg:text-justify"> {props.komentar} </p>
                        {props.alineje}
                    </div>
                </div>
        </div>

)


}
