import React from 'react';
import { Icon } from '@iconify/react';
import { Link, useLocation   } from "react-router-dom";
import * as source from "../projektiData.js";
import funFactData from "./footerData.js";

export default function Footer() {

let slikalevo = "/images/decek_levowhite.png";
let slikadesno = "/images/decek_desnowhite.png";
let leto = new Date().getFullYear();

function randomFact() {
    const cifra = Math.floor(Math.random() * funFactData.length);
    // let datum = funFactData[cifra].Date;
    //
    // datum = datum.slice(datum.indexOf(" "),(test.length-1));
    // console.log(datum);
     return(
         <div>
             <h3 className="steVedeli"> {funFactData[cifra].Date} {funFactData[cifra].Year} </h3>
             <p className="dejstvo"> {funFactData[cifra].Event} </p>
        </div>
     )
}


  const location = useLocation();

  React.useEffect(() => {
    randomFact();
  }, [location]);



let naLeta = 1999;

  return (
      <>
        <div className="h-50 bg-ozadje py-0 items-center">
            <div className="flex flex-row justify-center items-center h-full ">
                <div className="hidden sm:flex align-bottom items-baseline mb-5 mr-0 lg:w-32 w-1/4">
                    <img className="w-10/12 pt-4" src={slikalevo} alt="" />
                </div>
                <div className="flex flex-col items-center w-11/12 py-4 lg:w-1/3 h-full text-center text-white align-middle items-center" >
                    {randomFact()}
                </div>

                    <div  className="hidden sm:flex align-bottom items-baseline w-1/4 lg:w-max ">
                        <img className="w-10/12 py-2" src={slikadesno} alt="" />
                    </div>

            </div>
            <footer className="hidden lg:flex p-2 w-full h-12 bg-ozadjeSvetlo flex-col content-center items-center text-xs text-white normal-case shadow-outline">
                <p> Elektronika z robotiko | Vse pravice pridržane © {leto}</p>
                    <Link to="/politika-zasebnosti">
                        <span className="p-1 underline text-blue-600 hover:text-blue-800" >Politika zasebnosti</span>
                    </Link>

            </footer>

        </div>
      </>
)

}
